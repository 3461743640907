import React from "react";
import { Link } from "react-scroll";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  LinkedinShareButton,
  LinkedinIcon
} from "react-share";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="d-flex">
              <p>Portland Oregon</p>
            </div>
            <div className="d-flex">
              
            </div>
            <div className="d-flex">
              <p>josiahspeed@gmail.com</p>
            </div>
          </div>
         {/* 
          <div className="col-lg-3 col-md-2 col-sm-6">
            <div className="row">
              <div className="col">
              <ul className=" ml-auto">
                <li className="nav-item active">
                  <Link smooth={true} to="home" className="nav-link" href="#">Home <span className="sr-only">(current)</span></Link>
                </li>
                <li className="nav-item">
                  <Link smooth={true} to="about" offset={-110} className="nav-link" >about me</Link>
                </li>
                <li className="nav-item">
                  <Link smooth={true} to="services" offset={-110} className="nav-link" href="#">services</Link>
                </li>
                <li className="nav-item">
                  <Link smooth={true} to="experience" offset={-110} className="nav-link" >experience</Link>
                </li>
                <li className="nav-item">
                  <Link smooth={true} to="portfolio" offset={-110} className="nav-link" >portfolio</Link>
                </li>

                <li className="nav-item">
                  <Link smooth={true} to="contacts" offset={-110} className="nav-link" >contacts</Link>
                </li>
              </ul>
              </div>
            </div>
          </div>
         */}
          <div className="col-lg-5 col-md-5 col-sm-6 align-items-center">
            
            {/*<div className="d-flex justify-content-center">
              <FacebookShareButton
                url={""}
                quote={"FullStack Developer"}
                hashtag="#javascript"
              >
                <FacebookIcon className="mx-3" size={36} />
              </FacebookShareButton>
              <TwitterShareButton
                url={""}
                quote={"FullStack Developer"}
                hashtag="#javascript"
              >
                <TwitterIcon className="mx-3" size={36} />
              </TwitterShareButton>
              <RedditShareButton
                url={""}
                quote={"FullStack Developer"}
                hashtag="#javascript"
              >
                <RedditIcon className="mx-3" size={36} />
              </RedditShareButton>
              <LinkedinShareButton
                url={""}
                quote={"FullStack Developer"}
                hashtag="#javascript"
              >
                <LinkedinIcon className="mx-3" size={36} />
              </LinkedinShareButton>
            </div>
              */}
            <p className="pt-3 text-center">
              Copyright&copy;
              {new Date().getFullYear()}&nbsp;Josiah Speed | All Rights Reserved
            </p>
          </div>
        
        </div>
      </div>
    </div>
  )
}

export default Footer;
